.form-shippingScreen {
	max-width: 50rem;
	margin: 0 auto;
	margin-top: 0.2rem;
	margin-bottom: 10rem;
}

.form-shippingScreen > .div-col {
	display: flex;
	flex-direction: column;
	margin: 1rem;
}

.div-title {
	margin-left: 1rem;
	margin-right: 1rem;
}

.shippingTwoRow {
	margin-top: 0;
	margin-left: 0.3rem;
	margin-right: 0.3rem;
	display: flex;
}

.shippingCol {
	display: flex;
	justify-content: center;
	flex: 1;
	flex-direction: column;
	padding-left: 10px;
	padding-right: 10px;
}
.form-shippingScreen label {
	/*margin: 1rem 0; */
	margin-top: 0.4rem;
	margin-bottom: 0.2rem;
	margin-left: 0.2rem;
	font-weight: 600;
}

.form-shippingScreen input,
select {
	font-size: 1.1em;
	border: 2px solid #ccc;

	font-family: sans-serif;
	-webkit-appearance: none;
	border-radius: 10px;
	cursor: text;
	padding: 0.65rem;
}
.form-shippingScreen input:focus {
	outline: 0;
	border: 1px solid #0071e3;
	box-shadow: 0 0 0 4px rgba(0, 125, 250, 0.6);
}
.addressTitle {
	font-size: 36px;
	font-weight: 700;
	text-align: left;
}
.addressInfo {
	font-size: 1.8rem;
	font-weight: 500;
	color: #737888;
}

.shipping-button {
	background-color: #fc642d;
	font-size: 18px;
	color: white;
	border-radius: 9999px;
	font-weight: 700;
	padding-top: 11px;
	padding-bottom: 11px;
	padding-left: 54px;
	padding-right: 54px;
	margin: 5rem;
	margin-top: 0.8rem;
}

.shipping-button:hover {
	background-color: #ff7d66;
	scale: 1.05;
}
