.productInfo {
    min-width: 30rem;
    padding-left: 1rem;
    padding-right: 1rem;
}
.productInfo li {
    padding-bottom: 1rem;
}

.drag-area {
    border-radius: 6px;
    border: 2px solid rgba(156, 163, 175);
    color: black;
    width: 300px;
    height: 300px;
    overflow: hidden;
    /* cursor: url("https://uploads.codesandbox.io/uploads/user/b3e56831-8b98-4fee-b941-0e27f39883ab/Ad1_-cursor.png")
            39 39,
        auto; */
}

.ping-touch {
    animation: ping 1s cubic-bezier(0, 0, 0.2, 1) forwards;

    @keyframes ping {
        75%,
        100% {
            transform: scale(2);
            opacity: 0;
        }
    }

    @keyframes ping {
        0% {
            opacity: 1;
            visibility: visible;
        }

        100% {
            opacity: 0;
            visibility: hidden;
        }
    }
}

.font-family__select {
    font-size: 1.8rem;
    padding: 0;
}

/* Alignment */
