@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
    @font-face {
        font-family: "HILVCC+Boing";
        src: local("HILVCC+Boing-Medium"),
            url("./assets/fonts/Boing/HILVCCBoing-Medium.ttf")
                format("truetype");
        font-weight: 500;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: "HILVCC+Boing";
        src: local("HILVCC+Boing-Light"),
            url("./assets/fonts/Boing/HILVCCBoing-Light.ttf") format("truetype");
        font-weight: 300;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: "Boing";
        src: local("HILVCC+Boing-Regular"),
            url("./assets/fonts/Boing/HILVCCBoing-Regular.ttf")
                format("truetype");
        font-weight: 200;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: "Boing";
        src: local("HILVCC+Boing-Thin"),
            url("./assets/fonts/Boing/HILVCCBoing-Thin.ttf") format("truetype");
        font-weight: 100;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: "Boing";
        src: local("HILVCC+Boing-Semibold"),
            url("./assets/fonts/Boing/HILVCCBoing-Semibold.ttf")
                format("truetype");
        font-weight: 600;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: "Boing";
        src: local("HILVCC+Boing-Bold"),
            url("./assets/fonts/Boing/HILVCCBoing-Bold.ttf") format("truetype");
        font-weight: 700;
        font-style: normal;
        font-display: swap;
    }
}

html {
    font-size: 62.5%;
    /* 16px x 62.5 = 10px = 1rem */
    box-sizing: border-box;
}

:root {
    --primary-50: #f2fcf7;
    --primary-100: #dcf0e6;
    --primary-200: #c6e4d4;
    --primary-300: #c6e4d4;
    --primary-400: #9acbb0;
    --primary-500: #78b894;
    --primary-600: #4fa073;
    --primary-700: #2c9455;
    --primary-800: #126036;
    --primary-900: #0e4829;

    --bg-primary: #23232e;
    --bg-secondary: #141418;
    --transition-speed: 600ms;
}

body {
    margin: 0;
    height: 100vh;
    font-size: 1.6rem;
    -webkit-tap-highlight-color: transparent;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
[type="text"],
[type="email"],
[type="url"],
[type="password"],
[type="number"],
[type="date"],
[type="datetime-local"],
[type="month"],
[type="search"],
[type="tel"],
[type="time"],
[type="week"],
[multiple],
textarea,
select {
    font-size: 1.6rem;
}

input,
select,
textarea,
button {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#page-container {
    position: relative;
    min-height: 100vh;
}

#content-wrap {
    padding-bottom: 16rem; /* Footer height */
}

#footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 16rem; /* Footer height */
}

.page-loading-animate {
    animation: 0.1s fadeIn;
    animation-fill-mode: forwards;
    visibility: hidden;
}
.page-animate {
    animation: 0.3s fadeIn;
    animation-fill-mode: forwards;
    visibility: hidden;
}

.page-loaded-animate {
    animation: 0.2s fadeInGrow;
    animation-fill-mode: forwards;
    visibility: hidden;
}

.heading-1 {
    padding: 2rem 0 1rem;
    font-size: 2em;
    font-weight: 600;
}

.btn {
    @apply bg-orange-500 hover:bg-orange-400 hover:shadow-xl text-white font-bold py-2 px-4 rounded-full transition transform hover:scale-105;
}

.row {
    @apply flex flex-wrap justify-between items-center;
}

.row.center {
    @apply flex flex-wrap justify-center items-center;
}
.row.top {
    @apply flex items-start;
}

.col-center {
    flex: 0 1 50rem;
}

@media (min-width: 768px) {
    .col-center {
        flex: 0 1 40rem;
    }
}

@media (min-width: 2560px) {
    .col-center {
        flex: 0 1 60rem;
    }
}

.col-review {
    margin: 4rem 1rem 1rem 1rem;
    border-top: 1px solid #b7b7b7;

    flex: 0 1 100rem;
}

.col-related {
    margin: 4rem 1rem 1rem 1rem;

    flex: 0 1 100rem;
}

.col-1 {
    flex: 1 1 25rem;
}

.col-2 {
    flex: 2 1 50rem;
}
.col-3 {
    flex: 32 1 75rem;
}

img.large {
    width: 100%;
}
img.small {
    max-width: 5rem;
    width: 100%;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

/* Layout */

/* Image */

/* Card */

.price {
    font-size: 2rem;
}

/* Rating */
.rating span {
    color: crimson;
    margin: 0.1rem;
}

.rating span:last-child {
    color: black;
}

/*Alert*/

.success {
    color: aquamarine;
}

.danger {
    color: red;
}
.alert {
    padding: 1rem;
    border: 0.1rem solid transparent;
    border-radius: 0.5rem;
}
.alert-info {
    background-color: #e6e6e3;
    color: rgb(77, 75, 75);
    font-size: 0.9em;
    letter-spacing: 0.006em;
    margin-top: 0.5em;
    border-radius: 1rem;
    padding: 1rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.alert-danger {
    color: #a02020;
    background-color: #ffe0e0;
}
.alert-success {
    color: #20a020;
    background-color: #eeffe0;
}

/* Validation Errors handling */

.errorMsg {
    color: #de071c;
    font-size: 12px;
    letter-spacing: 0.006em;
    margin-top: 4px;
    margin-left: 2em;
    margin-right: 2em;
}

.errTooltip {
    background-color: #e6e6e3;
    color: rgb(77, 75, 75);
    font-size: 0.9em;
    letter-spacing: 0.006em;
    margin-top: 0.5em;
    border-radius: 1rem;
    padding: 1rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.errTooltip p {
    display: flex;
}

.errCheckmark {
    height: 2rem;
    width: 2rem;
    fill: rgb(77, 75, 75);
    padding-right: 0.8rem;
}

.successp {
    color: #519b31 !important;
}

.successp .errCheckmark {
    fill: #519b31 !important;
}

.errp {
    color: red;
}

/* Form */

.form {
    max-width: 60rem;
    margin: 0 auto;
}
.form > div {
    display: flex;
    flex-direction: column;
    margin: 1rem;
}
.form label {
    margin: 1rem 0;
}

/* Dropdown */
.dropdown {
    display: inline-block;
    position: relative;
    padding: 0.5rem;
    border-radius: 1rem;
}

.dropdown-content {
    position: absolute;
    display: block;
    top: 100%;
    right: -50%;
    min-width: 14rem;
    z-index: 1;
    background-color: #ffff;
    margin: 0;
    margin-top: 0.4rem;
    border-radius: 1rem;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
    transition: opacity 0.2s ease, transform 0.2s ease, visibility 0.2s;
}

.dropdown:hover {
    background-color: #eaeaea;
}
.dropdown:hover .dropdown-content {
    display: block;
    visibility: visible;
    z-index: 70;
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    transform: scale(1);
    transform: translateY(0);
}
.dropdown-content > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.5rem;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    text-align: start;
    width: 100%;
}
.dropdown-content > div:first-child {
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
}

.dropdown-content > div:last-child {
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
}
.dropdown-content > div:hover {
    background-color: #eaeaea;
}

/* Checkout Steps */
.checkout-steps > div {
    border-top: 0.3rem #c0c0c0 solid;
    color: #c0c0c0;
    flex: 1;
    padding: 1rem;
    font-size: 1em;
    font-weight: bold;
}
@media (max-width: 768px) {
    .checkout-steps > div {
        padding: 0.6rem;
    }
}
.checkout-steps > div.active {
    border-top-color: #fc642d;
    color: #fc642d;
}

/*Table*/

.table {
    width: 100%;
    border-collapse: collapse;
}
.table tbody tr:nth-of-type(odd) {
    background-color: #f4f4f4;
}
.table td,
.table th {
    text-align: left;
    border: 0.1rem solid #e4e4e4;
    padding: 0.5rem;
}
.table button {
    margin: 0 0.2rem;
}

/* Carousel */

.carousel .slide img {
    max-width: 30rem;
}
/* Search */

/* Overlay Common*/

.overlay-inactive {
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
    opacity: 0;
    visibility: hidden;
    transform: translateY(-30px);
    transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
}
.overlay-active {
    /* display: block; */

    visibility: visible;
    z-index: 70;
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    transform: scale(1);
    transform: translateY(0);
}

/*Pagination*/
.pagination a {
    padding: 1rem;
    margin: 0.5rem;
    border-radius: 0.5rem;
    border: 0.1rem #a4a4a4 solid;
    font-size: 1.6rem;
    font-family: Helvetica, Ariel, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.pagination a.active {
    font-weight: bold;
}
.active {
    font-weight: bold;
}

/* Text */
.page-title {
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
}
.section-title {
    font-size: 20px;
    line-height: 23px;
    font-weight: 600;
}

/* Quick Select Radio Button Group for Product Screen */
.radio-btn-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.radio-btn-wrapper {
    margin: 0px 4px 8px 0px;
}

.radio-btn {
    border: 2px solid black;
    border-radius: 0.6rem;
    color: #4a4a4a;
    font-size: 1.7rem;
    outline: none;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 2rem;
    padding-right: 2rem;
    background-color: #ffff;
}

.radio-btn-selected {
    border: 2px solid #fc642d;
    border-radius: 0.6rem;
    color: #4a4a4a;
    font-size: 1.7rem;
    outline: none;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 2rem;
    padding-right: 2rem;
    background-color: #ffebe4;
}

.radio-btn-selected:focus {
    outline: none;
    border: 2px solid #fc642d;
}

/* Font Picker */
#font-picker {
    width: "300px" !important;
}
.dropdown-button {
    width: "300px" !important;
}

/* Qty Selector */

.quantity-input {
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    border-radius: 10px;
}
.quantity-input:focus {
    background: red;
}

.quantity-input__modifier,
.quantity-input__screen {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    outline: none;
}

.quantity-input__modifier {
    padding: 1rem;
    width: 5rem;
    font-size: 1.5rem;
    background: #f3f3f3;
    color: #888;
    border: 0 solid #dbdbdb;
    text-align: center;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.6);
    cursor: pointer;
}
.quantity-input__modifier:hover {
    background: #dadada;
    color: #555555;
}
.quantity-input__modifier--left {
    border-radius: 10px 0 0 10px;
}
.quantity-input__modifier--right {
    border-radius: 0 10px 10px 0;
}

.quantity-input__screen {
    width: 4rem;
    padding: 0.7rem;
    font-size: 1.5rem;
    border: 0;
    border-top: 0 solid #dbdbdb;
    border-bottom: 0 solid #dbdbdb;
    text-align: center;
}
.quatity-body {
    display: flex;
    padding: 1rem 0 1rem 0;
    align-items: center;
    text-align: center;
}
/* Navbar */

.navbar {
    background-color: var(--primary-700);
    color: var(--primary-200);
    border-bottom: 0.2rem solid rgba(156, 163, 175, 0.8);
    position: sticky;
    top: 0px;
    z-index: 10;
    width: 100%;
    @media screen and (min-width: 769px) {
        position: relative;
    }
}
.desktopNav {
    display: none;
}
@media screen and (min-width: 769px) {
    .desktopNav {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin: 0 5rem 0 5rem;
    }
}

.desktopNav > div:first-child {
    display: flex;
    width: 100%;
    margin: 1rem 0 1rem 0;
    align-items: center;
    justify-content: center;
}
.desktopNav > div:last-child {
    display: flex;
    width: 80%;
    margin: 1rem 0 1rem 0;
    align-items: center;
    justify-content: space-evenly;
}

.desktopnav-search {
    width: 60%;
}
/* Mobile Navbar */
.mobileNav {
    display: none;
}
@media screen and (max-width: 768px) {
    .mobileNav {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0.5rem 0 0.5rem 0;
        justify-content: center;
        align-items: center;
    }
}

.mobileNav > div:first-child {
    font-size: 2.875rem;
}
.mobileNav > div:last-child {
    width: 100%;
    margin: 2rem;
}

/* TabBar */

.tabbar {
    display: none;
}
@media screen and (max-width: 768px) {
    .tabbar {
        display: block;
        position: fixed;
        border-top-left-radius: 0.75rem /* 12px */;
        border-top-right-radius: 0.75rem /* 12px */;
        border-top-width: 2px;
        background-color: rgba(255, 255, 255);
        right: 0px;
        left: 0px;
        bottom: 0px;
        width: 100vw;
        z-index: 10;
        padding-bottom: 1rem;
    }
}

.tabbar-container {
    display: flex;
    align-content: center;
    justify-content: space-around;
    place-items: center;
    padding: 0.5rem 1rem 0.5rem 1rem;
}

.tabbar-items {
    display: flex-column;
    justify-content: center;
    align-content: center;
    place-items: center;
    padding: 0.2rem 0.5rem 0.2rem 0.5rem;
    font-size: 1.4rem;
    transition: 0.25s ease;
}
.notification-tabbar {
    position: relative;
    display: inline-block;
}

.notification-tabbarcart {
    display: inline-flex;
    position: absolute;
    bottom: 1.5rem;
    right: 1.25rem;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1;
    font-size: 0.8rem;
}

.tabbar-items svg {
    display: block;
    margin: auto;
    transition: 0.25s ease;
}
.tabbar-items:hover,
.tabbar-items:focus {
    color: var(--primary-700);
    border-radius: 12px;
}
.tabbar-items button:focus {
    outline: none;
    border-radius: 12px;
    background-color: var(--primary-50);
}

.tabbar-items-active {
    color: var(--primary-700);
    background-color: none;
    outline: none;
}

/* Buttons */

/* Button Add to Cart */
.Button {
    background: #fc642d;
    border: none;
    border-radius: 10em;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.4);
    color: #fff;
    cursor: pointer;
    font-size: 2.4rem;
    font-weight: bold;
    padding: 0.5em 1em;
    transition-property: background-color, box-shadow, transform;
    transition-timing-property: ease-out;
    transition-duration: 0.3s;
}

.Button:hover,
.Button:focus {
    background: #fc642d;
    box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.4);
    transform: translateY(-2px);
}

.Button:active {
    background: #fc642d;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.4);
    transform: translateY(0) scale(0.975);
}
/* Button End Add to Cart */

.uibutton {
    border-width: 1px;
    border-color: transparent;
    border-radius: 0.375rem;
    background-color: var(--primary-100);
    color: var(--primary-900);
    padding: 1rem;
    margin: 1rem 0 1rem 0;
}
.uibutton:hover {
    background-color: var(--primary-200);
}

.uibutton:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
}
.uibutton:focus-visible {
    outline: 2px solid var(--primary-300);
    outline-offset: 2px;
    box-shadow: 0 0 0 var(2px) var(--primary-300), var(--primary-100);
}

.uibutton-fileupload {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.5rem 1rem 1.5rem 1rem;
    background-color: white;
    color: var(--primary-500);
    border-radius: 0.5rem;
    letter-spacing: 0.025em;
    text-transform: uppercase;
    border: 1px solid var(--primary-400);
    width: 16rem;
    cursor: pointer;
}

.uibutton-fileupload:hover {
    background-color: var(--primary-600);
    color: var(--primary-100);
}

/* HomeScreen2 */
.break {
    flex-basis: 100%;
    height: 0;
}

/* Scrolling Carousel */

.section-scrolling-carousel {
    display: block;
    position: relative;

    margin: 0 !important;
}

.scrolling-carousel {
    display: block;
    position: relative;
    overflow-x: auto !important;
    overflow-y: hidden !important;
    transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
}

.scrolling-carousel::-webkit-scrollbar {
    display: none;
}

.carousel-container {
    display: flex;
}

.carousel-card-container {
    /*vertical-align: top;*/
    display: flex;
    padding: 1rem;
    scroll-snap-align: center;
}

.carousel-card-container:focus-within {
    outline: -webkit-focus-ring-color auto 1px;
}

.more-container {
    display: grid;
    place-items: center;
    text-align: center;
}

.more-line {
    background-color: #dadce0;
    border: 0;
    height: 1px;
    left: 0;
    margin-top: 18px;
    position: absolute;
    width: 100%;
}
.more-container-button {
    background: #fff;
    border: 1px solid #dadce0;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    line-height: 20px;
    pointer-events: auto;
    position: relative;
    width: 300px;
    padding: 7px 11px;
    height: 36px;
    border-radius: 18px;
}
.more-container-button:hover {
    background: #fafafa;
}
.more-arrow {
    color: rgba(0, 0, 0, 0.54);
    float: left;
    margin: -2px 24px -2px 0;
}

.more-container-button .more-arrow {
    left: 12px;
    margin-top: 0;
    position: absolute;
    height: 20px;
    width: 20px;
}
.more-text {
    color: rgba(0, 0, 0, 0.87);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: auto;
}
.more-text:hover {
    color: #1a0dab;
    white-space: initial;
}

.more-container-button .more-text {
    max-width: 220px;
    white-space: nowrap;
}

.arrow-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.04), 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
    height: 36px;
    width: 36px;
    border-radius: 50%;
    border: 1px solid #dfe1e5;
    background: rgba(255, 255, 255, 0.84);
    margin: 0rem -1rem 0 -1rem;
}

.arrow-buttons:focus {
    outline: none;
}

.arrow-buttons svg {
    fill: #70757a;
}

/*Range Selector */
input[type="range"] {
    height: 30px;
    -webkit-appearance: none;
    margin: 10px 0;
    width: 100%;
}
input[type="range"]:focus {
    outline: none;
}
input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 3px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: #fc642d;
    border-radius: 5px;
    border: 0px solid #000000;
}
input[type="range"]::-webkit-slider-thumb {
    box-shadow: 2px 2px 2px rgba(252, 100, 45, 0.2);
    border: 0px solid #000000;
    height: 22px;
    width: 22px;
    border-radius: 25px;
    background: #fc642d;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -9.5px;
}
input[type="range"]:focus::-webkit-slider-runnable-track {
    background: #fc642d;
}
input[type="range"]:focus::-webkit-slider-thumb {
    background: #fc642d;
    border: 4px solid rgba(252, 100, 45, 0.2);
}
input[type="range"]:focus::-moz-range-thumb {
    background: #fc642d;
    border: 4px solid rgba(252, 100, 45, 0.2);
}
input[type="range"]:focus::-ms-thumb {
    background: #fc642d;
    border: 4px solid rgba(252, 100, 45, 0.2);
}
input[type="range"]::-moz-range-track {
    width: 100%;
    height: 3px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: #fc642d;
    border-radius: 5px;
    border: 0px solid #000000;
}
input[type="range"]::-moz-range-thumb {
    box-shadow: 2px 2px 2px #fc642d;
    border: 0px solid #000000;
    height: 22px;
    width: 22px;
    border-radius: 25px;
    background: #fc642d;
    cursor: pointer;
}
input[type="range"]::-ms-track {
    width: 100%;
    height: 3px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
}
input[type="range"]::-ms-fill-lower {
    background: #fc642d;
    border: 0px solid #000000;
    border-radius: 10px;
    box-shadow: 0px 0px 0px #000000;
}
input[type="range"]::-ms-fill-upper {
    background: #fc642d;
    border: 0px solid #000000;
    border-radius: 10px;
    box-shadow: 0px 0px 0px #000000;
}
input[type="range"]::-ms-thumb {
    margin-top: 1px;
    box-shadow: 2px 2px 2px #fc642d;
    border: 0px solid #000000;
    height: 22px;
    width: 22px;
    border-radius: 25px;
    background: #fc642d;
    cursor: pointer;
}
input[type="range"]:focus::-ms-fill-lower {
    background: #fc642d;
}
input[type="range"]:focus::-ms-fill-upper {
    background: #fc642d;
}

/* Accessablity */

.screen-reader-only {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

/* Animations - Common */

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        visibility: visible;
        opacity: 1;
    }
}

@keyframes fadeOut {
    100% {
        visibility: visible;
        opacity: 1;
    }
    0% {
        opacity: 0;
    }
}

@keyframes fadeInRight {
    0% {
        opacity: 0;
        transform: translateX(20px);
    }
    100% {
        opacity: 1;
        visibility: visible;
        transform: translateX(0);
    }
}
@keyframes fadeInTop {
    0% {
        opacity: 0;
        transform: translateY(-60px);
    }
    100% {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
    }
}
@keyframes fadeInGrow {
    from {
        opacity: 0;
        transform: scale(0);
    }
    to {
        opacity: 1;
        visibility: visible;
        transform: scale(1);
    }
}
@keyframes typeFadeIn {
    0% {
        width: 0%;
    }
    40% {
        width: 0%;
    }
    60% {
        width: 0%;
    }
    100% {
        width: 100%;
    }
}

/* Loading Dots */
.loader-dots div {
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.loader-dots div:nth-child(1) {
    left: 8px;
    animation: loader-dots1 0.6s infinite;
}
.loader-dots div:nth-child(2) {
    left: 8px;
    animation: loader-dots2 0.6s infinite;
}
.loader-dots div:nth-child(3) {
    left: 32px;
    animation: loader-dots2 0.6s infinite;
}
.loader-dots div:nth-child(4) {
    left: 56px;
    animation: loader-dots3 0.6s infinite;
}
@keyframes loader-dots1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes loader-dots3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}
@keyframes loader-dots2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(24px, 0);
    }
}

/* tickmark */
.ping-tickmark {
    animation: scaleIt 1s ease-in;
    animation-delay: 1000ms;

    @keyframes scaleIt {
        from {
            opacity: 0;
            transition: opacity 1s;
            transform: scale(2, 2);
        }
        to {
            opacity: 1;
            transform: scale(1, 1);
        }
    }
}

/*HomePage styling*/

/* Heading Styles */

.homepageHeadings {
    /* font-family: HILVCC + Boing-Semibold; */
    -webkit-text-stroke: 4.7px #ffffff;
    font-size: 6.3em;
    line-height: 10.4rem;
    font-weight: 700;
    color: transparent;
    padding: 0 0 2rem 2.5rem;
}

@media (max-width: 768px) {
    .homepageHeadings {
        padding: 0 0 2rem 0;
    }
}

/* Feature Css*/
.feature-square {
    min-width: 261px;
    height: 258px;

    border: 9px dashed #30323e;
    box-sizing: border-box;
    border-radius: 10px;
}

/*MailChip*/
.mailchip_input > input[type="email"] {
    border: 5px solid #000000;
    padding-left: 1rem /* 16px */;
    padding-right: 1rem /* 16px */;
    padding-top: 0.5rem /* 8px */;
    padding-bottom: 0.5rem /* 8px */;
    margin-bottom: 1rem /* 16px */;
    font-size: 1.6rem;
}

@media (min-width: 768px) {
    .mailchip_input > input[type="email"] {
        margin-right: 0.5rem /* 8px */;
        margin-bottom: 0px;
    }
}

.mailchip_input > button {
    width: 183px;
    height: 45px;
    font-weight: 600;
    font-size: 25px;
    background-color: black;
    color: white;
    border-radius: 0.75rem;
}
