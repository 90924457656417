.form-signin {
    max-width: 40rem;
    margin: 0 auto;
}

.field {
    display: flex;
    flex-flow: column-reverse;
    margin-left: 1em;
    margin-right: 1em;
}

.label-signin {
    color: #666;
    font-size: 1.2rem;
}
.label-signin,
.input-signin {
    transition: all 0.2s;
    touch-action: manipulation;
    line-height: 1.23536;
    font-weight: 400;
}

.input-signin {
    font-size: 1.05em;
    border: 1px solid #ccc;
    font-family: sans-serif;
    -webkit-appearance: none;
    border-radius: 12px;

    cursor: text;
    padding: 1.78882rem 1.2rem 0.4rem;
}

.input-signin:focus {
    outline: 0;
    border: 1px solid #0071e3;
    box-shadow: 0 0 0 4px rgba(0, 125, 250, 0.6);
}

.label-signin {
    letter-spacing: 0.02em;
}

.input-signin:placeholder-shown + .label-signin {
    cursor: text;
    max-width: 66.66%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transform-origin: left bottom;
    transform: translate(1.2rem, 3.2rem) scale(1.3);
}

.input-signin::placeholder {
    opacity: 0 !important;
    transition: inherit;
}
.input-signin:focus::placeholder {
    opacity: 1 !important;
}

.input-signin:not(:placeholder-shown) + .label-signin,
.input-signin:focus + .label-signin {
    transform: translate(1em, 1.55em) scale(1);
    cursor: pointer;
}

.h1-message {
    font-size: 3.2rem;
    font-weight: 700;
    text-align: center;
}

.p-message {
    font-size: 1.6rem;
    font-weight: 400;
    text-align: center;
    padding-top: 1.5rem;
}

.button-center {
    display: flex;
    flex-flow: column;
    margin-left: 3em;
    margin-right: 3em;
    justify-content: center;
}

.login-button {
    background-color: #fc642d;
    font-size: 18px;
    color: white;
    border-radius: 9999px;
    font-weight: 700;
    padding-top: 11px;
    padding-bottom: 11px;
    padding-left: 54px;
    padding-right: 54px;
    margin: 1rem;
    margin-top: 54px;

    /*margin-left: 8.8rem; */
}
.login-button:hover {
    background-color: #ff7d66;
    scale: 1.05;
}

/*Signup specific functions*/
.info-signup {
    margin-left: 2rem;
    margin-right: 2rem;
}
.h1-signup-message {
    font-size: 3.2rem;
    font-weight: 700;
    text-align: left;
}

.p-signup-message {
    font-size: 1.4rem;
    font-weight: 400;
    text-align: left;
    padding-top: 0.5rem;
    padding-bottom: 2rem;
}

.signup-button {
    background-color: #fc642d;
    font-size: 18px;
    color: white;
    border-radius: 9999px;
    font-weight: 700;
    padding-top: 11px;
    padding-bottom: 11px;
    padding-left: 54px;
    padding-right: 54px;
    margin: 1rem;
    margin-top: 54px;
}
